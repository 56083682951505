import { Outlet } from 'react-router-dom';
import Header from "../components/Header/Header"

export default function BaseLayout({ userProfile, onSignOut }) {

    function logout(){
        const params = {
            "UID":userProfile.UID
        }
        window.gigya.accounts.logout(params);
        console.log(userProfile.UID, 'logout operation')
    }
  return (<div>
    <Header userProfile={userProfile} onSignOut={onSignOut}/>
    <Outlet/>
  </div>);
}
