import "./App.css";
import FileUpload from "./components/FileLoader/FileLoader";
import { useLayoutEffect, useState, useEffect } from "react";
import {
  Routes,
  Route,
  useLocation,
  Navigate,
  BrowserRouter,
} from "react-router-dom";
import ErrorPage from "../src/pages/ErrorPage";
import BaseLayout from "./layout/BaseLayout";
import LoginPage from "./pages/LoginPage";
import ConsentPage from "./pages/ConsentPage";
import RegisterPage from "./pages/RegisterPage";
import CircularIndeterminate from "./mui-components/CircularIndeterminate";
import Proxy from "./pages/Proxy";
import {
  setUserInfoToLocalStorage,
  getUserInfoFromLocalStorage,
  removeUserInfoFromLocalStorage,
} from "../src/utils/localStorageInterect";
import {
  validateToken,
  refreshAccessToken,
  getAccountInfo,
} from "../src/api/gigyaApi";
import TestLoginPage from "../src/pages/TestLoginPage";
import ConsentStatement from "../src/pages/consent-statement/ConsentStatement";


const CookieConsentComponent = () => {

  useEffect(() => {
    // Function to dynamically load the script
    const loadScript = () => {
      const script = document.createElement('script');
      script.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
      script.type = 'text/javascript';
      script.charset = 'UTF-8';
      script.setAttribute('data-domain-script', '01919e75-2c08-7f9d-b689-4d0f025c930a');
      script.async = true;

      // Append the script to the document
      document.body.appendChild(script);

      // Function called by OneTrust once loaded
      script.onload = () => {
        window.OptanonWrapper = function() {
          // Your logic if needed when the OneTrust script is loaded
        };
      };
    };

    // Set a timeout for 45 seconds to load the script
    const timer = setTimeout(() => {
    //  loadScript();
    }, 4500); // 45000 milliseconds = 45 seconds

    // Cleanup the timeout when the component is unmounted
    return () => clearTimeout(timer);

  }, []);

  return (
    <div>
      {/* Add any content or indication here if you want */}
      <p>Cookie consent will be loaded after 4,5 seconds.</p>
    </div>
  );
};

function App() {
  const [userProfile, setUserProfile] = useState({});
  const [isSessionActive, setSessionStatus] = useState(null);

  /* OneTrust Cookies Consent Notice start for cspartner.bayer.com */
  const loadScript = () => {
    const script = document.createElement('script');
    script.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
    script.type = 'text/javascript';
    script.charset = 'UTF-8';
    script.setAttribute('data-domain-script', '01919e75-2c08-7f9d-b689-4d0f025c930a');
    script.async = true;

    // Append the script to the document
    document.body.appendChild(script);

    // Function called by OneTrust once loaded
    script.onload = () => {
      window.OptanonWrapper = function() {
        // Your logic if needed when the OneTrust script is loaded
      };
    };
  };

  useEffect(()=>{
    let gigyaCache = sessionStorage.getItem('gigyaCache');
    console.log("Gigya Cache:", gigyaCache);

    if(!!gigyaCache){
      loadScript();
    }
    
  })

  async function validateSession() {
    const savedData = getUserInfoFromLocalStorage();

    if (savedData) {
      const accessToken = savedData?.accessToken;
      const refreshToken = savedData?.refreshToken;
      const email = savedData?.email;
      const uid = savedData?.uid;
      const resultOfValidationToken = await validateToken(accessToken);

      if (resultOfValidationToken?.active) {
        setUserProfile({ email, uid });
        setSessionStatus("OK");
      } else {
        const responce = await refreshAccessToken(refreshToken);
        if (responce?.access_token && responce?.refresh_token) {
          const resultOfValidationToken = await validateToken(
            responce?.access_token
          );
          if (resultOfValidationToken?.active) {
            const { email, uid } = await getAccountInfo(responce?.access_token);
            setUserInfoToLocalStorage(
              responce?.access_token,
              responce?.refresh_token,
              email,
              uid
            );
            setUserProfile({ email, uid });
            setSessionStatus("OK");
          } else {
            setSessionStatus("FAIL");
            console.log(resultOfValidationToken, "fail-1");
          }
        } else {
          setSessionStatus("FAIL");
          console.log("fail-2");
        }
      }
    } else {
      setSessionStatus("FAIL");
      console.log("fail-3");
    }
  }

  function signOut() {
    removeUserInfoFromLocalStorage();
    setSessionStatus("FAIL");
    window?.gigya.accounts.logout(userProfile?.uid);
  }

  useLayoutEffect(() => {
    validateSession();
  }, []);

  return (
    <BrowserRouter>
      {isSessionActive === null ? (
        <CircularIndeterminate />
      ) : (
        <Routes>
          <Route
            path="/login"
            element={<LoginPage onLogIn={setUserProfile} />}
          />
        <Route
            path="/pre-login"
            element={<CookieConsentComponent/>}
          />
          <Route path="/consent" element={<ConsentPage />} />
          <Route
            path="/proxy"
            element={
              <Proxy
                onSetCorrectData={setSessionStatus}
                onSetUserData={setUserProfile}
              />
            }
          />
          <Route path="/proxy.html" element={<div></div>} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/error" element={<ErrorPage />} />
          <Route path="/test-login" element={<TestLoginPage />} />
          <Route path="/privacy-statement" element={<ConsentStatement />} />
          <Route
            path="/"
            element={
              <RequireAuth status={isSessionActive}>
                <BaseLayout
                  onSignOut={signOut}
                  userProfile={userProfile}
                ></BaseLayout>
              </RequireAuth>
            }
          >
            <Route index element={<Navigate to="/send-reports" replace />} />
            <Route
              path="/send-reports"
              element={<FileUpload userProfile={userProfile} />}
            />
            <Route path="/history" element={<div>History</div>} />
            <Route
              path="/*"
              element={<Navigate to="/send-reports" replace />}
            />
          </Route>
        </Routes>
      )}
    </BrowserRouter>
  );
}

function RequireAuth({ children, status }) {
  let location = useLocation();
  if (status === 'FAIL') {
     return <Navigate to="/login" state={{ from: location }} replace />;
  }
  if (status === "OK") {
    console.log("show childrens");
    return children;
  }
}

export default App;
